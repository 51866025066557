.clients_container{
    width: 100%;
    height: fit-content;
    justify-content: center;
    padding: 10vh 0 3vh 0;
    /* background-image: url('../../../assets/homebground.png');
    background-repeat: no-repeat;
    background-size: cover; */
    color: #fff;
    background: linear-gradient(57.2deg, #121D85 10.21%, #000000 100%);
}

.clients_head{
    width: 100%;
    height: fit-content;
    justify-content: center;
    text-align: center;
    color: #FFF;
    font-family: Metropolis;
    font-size: calc(0.5rem + 1.5vw);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01rem;
    padding-bottom: 1%;
}


.clients_head h1{
    text-shadow: 1px 1px 8px rgba(255, 255, 255), 1px 1px 8px rgba(255, 255, 255);
    padding: 0vh 0 0vh 0;
}

.clients_head h2{
    margin: 0vh 0 -2vh 0;
}

.clients_head h4{
    padding: 0vh 0 2vh 0;
}

.swiper{
    position: relative;
    width: 100%;
    /* z-index: 100; */
}


.bottom{
    padding: 4vh 0 8vh 0;
    color: #FFF;
    font-family: Metropolis;
    font-size: calc(0.7rem + 0.4vw);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01rem;
}

.bottom h1{
    line-height: 130%;
    text-shadow: 2px 2px 20px rgba(255, 255, 255), 2px 2px 20px rgba(255, 255, 255);
}

.bottom button{
    background-color: transparent;
    border: 1px solid white;
    color: #fff;
    width: 20vw;
    height: 5vh;
    margin-top: 5vh;
    box-shadow: 0px 0px 5px 0px #FFF, 0px 0px 5px 0px #FFF;
}


.bottom button:hover{
  box-shadow: 0px 0px 15px 0px #FFF, 0px 0px 15px 0px #FFF;
}

@media screen and (max-width: 950px) {
    .clients_container{
        padding-top: 8vh;
    }
    .clients_head{
        width: 90%;
        margin-left: 5%;
        padding-bottom: 5%;
    }
    .clients_head h5{
        padding-bottom: 2%;
    }
    .clients_head h1{
        line-height: 130%;
    }
    .bottom{
        width: 95%;
        margin-left: 2.5%;
    }
  }