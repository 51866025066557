.trends_container {
    width: 100%;
    height: 90vh;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 5vh 0 10vh 0;
  }
  
  .trends_content {
    width: 80%;
    margin-left: 10%;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #fff;
    font-family: Metropolis;
    font-style: normal;
  }

  .title_container{
    display: flex;
    flex-direction: row;
    text-align: start;
    column-gap: 10%;
    /* padding-left: 2%; */
    font-size: calc(0.5rem + 1.5vw);
  }
  
  .title_container h1{
    font-weight: 800;
    letter-spacing: 0.18px;
    line-height: 2.5rem; /* 21.42px */
    text-shadow: 2px 2px 20px rgba(255, 255, 255), 2px 2px 20px rgba(255, 255, 255);
  }
  
  .trends_content p{
    font-size: calc(0.9rem + 0.2vw);
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 130%;
    padding-top: 02%;
  }
  
  .swiper{
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: 5vh;
      justify-content: center;
      text-align: center;
      align-items: center;
  }

  .arrowDesktop{
    display: flex ;
  }

  @media screen and (max-width: 950px){
    .swiper{
      margin-top: 2vh;
  }
    .trends_container {
      width: 100%;
      height: fit-content;
      padding: 5vh 0 5vh 0;
    }
    .arrowDesktop{
        display: none;
    }
    .trends_content p{
      padding-top: 0%;
    }
    .title_container{
      flex-direction: column;
      text-align: center;
    }
  }