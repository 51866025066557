.contact_container {
    width: 100%;
    height: fit-content;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #fff;
    font-family: Metropolis;
    font-style: normal;
    padding: 5vh 0 10vh 0;
  }

  .title_container{
    display: flex;
    flex-direction: column;
      text-align: center;
  }
  
  .title_container h1{
    font-size: calc(1.5rem + 1vw);
    font-weight: 800;
    letter-spacing: 0.18px;
    line-height: 2.5rem; /* 21.42px */
    text-shadow: 2px 2px 20px rgba(255, 255, 255), 2px 2px 20px rgba(255, 255, 255);
  }
  
  .title_container p{
    font-size: calc(0.7rem + 0.7vw);
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 1.5rem; /* 21.42px */
  }

  .button{
    width: 100%;
    margin: 5vh 0vw 0vh 0vw;
}

.button button{
    padding: 1vh 3vw 1vh 3vw;
    background-color: transparent;
    box-shadow: 0px 0px 5px 0px #FFF, 0px 0px 5px 0px #FFF;
    color: #FFF;
    cursor: pointer;
}

.button button:hover{
    box-shadow: 0px 0px 15px 0px #FFF, 0px 0px 15px 0px #FFF;
}
.bubbleImg{
  position: absolute;
  margin-top: -15%;
  margin-left: -55%;
  z-index: 90;
  overflow: hidden;
  
}

@media screen and (max-width: 950px){
  .bubbleImg{
    width: 35%;
    margin-top: -20%;
    margin-left: -65%;
    
  }
}