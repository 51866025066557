.home_container{
  position: relative;
    width: 100%;
    height: fit-content;
    justify-content: center;
    /* background-image: url('../../../assets/homebground.png'); */
    /* background-repeat: no-repeat;
    background-size: cover; */
    overflow: hidden;
    z-index: 0;
    background: linear-gradient(57.2deg, #121D85 10.21%, #000000 100%);

}
.videoContainer{
 position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%  ;
    z-index: 1;
    overflow: hidden;
}

.videoContainer video {
  width: 100%;
  height: 100%;
   object-fit: cover;
 }
.videoContainer img {
   width: 100%;
    object-fit: contain;
  }
  

  .home_content{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align:center ;
    margin-left: 10%;
  }
  
  .head{
    align-items: center;
    text-align:center ;
    padding-top: 30vh;
    padding-bottom: 40vh;
    font-family: Metropolis;
    font-size: calc(0.5rem + 1.5vw);
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    z-index: 10;
    
  }

  .head h1{
    color: white;
    text-shadow: 1px 1px 8px rgba(255, 255, 255), 1px 1px 8px rgba(255, 255, 255);
  }

  .head h4{
    color: white;
  }

  .head button{
    background-color: transparent;
    color: #fff;
    border: 1px solid white;
    cursor: pointer;
    width: 267px;
    height: 44px;
    font-family: Metropolis;
    box-shadow: 0px 0px 5px 0px #FFF, 0px 0px 5px 0px #FFF;
}


.head button:hover{
  box-shadow: 0px 0px 15px 0px #FFF, 0px 0px 15px 0px #FFF;
}

  .head img{
    position :absolute;
    top: 35%;
    left: 10%;
    color: #fff;
    bottom: 8rem;
    font-weight: 300;
    font-size: 0.9rem;
  }

  .bottom{
    align-items: center;
    text-align:center ;
    padding-top: 30vh;
    padding-bottom: 40vh;
    font-family: Metropolis;
    font-size: calc(0.5rem + 1.5vw);
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    z-index: 10;
  }

  .bg{
    position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 9;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0.04%, #000433 100%);
  }

  .bottom h1{
    color: white;
    line-height: 0;
    text-shadow: 1px 1px 8px rgba(255, 255, 255), 1px 1px 8px rgba(255, 255, 255);
  }
  
  .bottom h6{
    width: 70%;
    color: white;
    margin-left: 15%;
    font-weight: 300;
    line-height: 150%;
    font-size: calc(0.9rem + 0.2vw);
    text-align: center;
  }

  .bottom button{
    background-color: transparent;
    color: #fff;
    border: 1px solid white;
    width: 267px;
    height: 44px;
    font-family: Metropolis;
    box-shadow: 0px 0px 5px 0px #FFF, 0px 0px 5px 0px #FFF;
}


.bottom button:hover{
  box-shadow: 0px 0px 15px 0px #FFF, 0px 0px 15px 0px #FFF;
}

@media screen and (max-width: 950px){
  .home_container video {
    width: 100%;
  }
  .videoContainer{
    /* top: 10vh; */
  }
  .home_content{
    width: 100%;
    margin-left: 0%;
    text-align: center;
  }
  .head{
    padding-top: 15vh;
    padding-bottom: 5vh;
  }
  .head img{
    display: none;
  }
  .bottom{
    padding-top: 10vh;
    padding-bottom: 5vh;
  }
  .bottom button{
    display: none;
}
}
@media screen and (max-width: 650px){
  .home_container video {
    width: 150%;
  }
  .videoContainer{
    /* top: 15vh; */
  }
}