.container{
    background-image: url("../../../assets/homebground.png");
    height: 100%;
    text-align: center;
}

.text{
    color: #fff;
    text-align: center;
}

.logo{
    width: 30%;
    justify-content: center;
    margin-bottom: 20%;
    z-index: 55;
}