.contactus_container{
  position: relative;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    cursor: none;
    background: linear-gradient(55.53deg, #121D85 12.46%, #000000 100%);
}
.customcursor {
  position: absolute;
  width: 250px; /* Adjust as needed for the desired button size */
  height: 250px;
  /* background-color: #ff3c00; Adjust background color as needed */
  border: 1px solid #fff;
  border-radius: 50%;
  pointer-events: none; /* This ensures the cursor doesn't interfere with interactions */
  opacity: 1; /* Adjust opacity as desired */
  transform: translate(-50%, -50%);
  z-index: 99;
  color: #fff; box-shadow: 0px 0px 15px 0px #FFF, 0px 0px 15px 0px #FFF;
}
.news_content {
  width: 80%;
  margin-left: 10%;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #121d85;
  font-family: Metropolis;
  font-size: calc(0.7rem + 0.4vw);
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05rem;
}

.videoCon {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content ;
    object-fit: contain;
    z-index: 1;
    overflow: hidden;
    margin-bottom: -1%;
  }
  .video {
    width: 100%;
    height: 90vh;
    object-fit: cover;
  }
  .panel {
    height: fit-content;
    width: 100vw;
    text-align: center;
    position: relative;
    display: block;
    top: 50%;
    z-index: 0;
  }

  .mobilepanel{
    display: none;
  }
 
  .header {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60vh;
    margin: auto;
    padding-top: 10vh ;
    display: flex;
    flex-direction: column;
    color: #fff;
    justify-content: center;
    align-items: center;
    row-gap: 20vh;
    text-shadow: 0 0 3px rgba(255, 255, 255), 0 0 3px rgba(255, 255, 255);
    font-family: Metropolis;
    font-size: calc(1rem + 0.4vw);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media screen and (max-width: 950px){
    .panel {
      display: none;
    }
    .mobilepanel {
      display: flex;
    }
    .video{
      height: 70vh;
    }
    .header {
      height: 50vh;
    }
    .news_content{
      padding-top: 6%;
    }
  }