.news_container {
  width: 100%;
  height: fit-content;
  justify-content: center;
  text-align: center;
  align-items: center;
  /* padding-top: 5%; */
  margin-bottom: 90vh;
}

.news_content {
  width: 80%;
  margin-left: 10%;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #121d85;
  font-family: Metropolis;
  font-size: calc(0.5rem + 1.5vw);
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05rem;
}


.news_content h1{
  line-height: 20%;
    font-weight: 800;
    text-shadow: 2px 2px 20px rgba(37, 78, 199, 0.25), 2px 2px 20px rgba(37, 78, 199, 0.25);
}

.news_content h2{
    font-weight: 700;
}

.news_content h4{
  line-height: 20%;
    font-weight: 400;
}

.swiper{
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

@media screen and (max-width: 950px){
  .news_container {
    margin-bottom: 0vh;
    padding-top: 3%;
  }
  .swiper{
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
  .news_content {
    width: 100%;
    margin-left: 0%;
  }
  .news_content h1{
    line-height:100%;
    margin-top: -3%;
  }
  .news_content h4{
    line-height: 130%;
  }
}