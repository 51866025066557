.blogintro_container{
    width: 100%;
    height: fit-content;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 10vh 0 10vh 0;
}

.small_img{
    position: absolute;
    right: 0;
    top: 15vh;
}

.header{
    width: 80%;
    margin-left: 10%;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 10vh;
    color: #121D85;
font-family: Metropolis;
font-style: normal;
    font-size: calc(0.5rem + 1.5vw);
}

.header h1{
    font-weight: 800;
    letter-spacing: 0.18px;
    line-height: 2.5rem; /* 21.42px */
    text-shadow: 2px 2px 20px rgba(18, 29, 133, 0.32), 2px 2px 20px rgba(18, 29, 133, 0.32);
    padding-bottom: 3vh;
}

.header p{
    font-size: calc(0.9rem + 0.2vw);
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 1.5rem; /* 21.42px */
    padding-bottom: 3vh;
}

.image{
    width: 100%;
    height: 70vh;
    object-fit: cover;
}

.body{
    width: 80%;
    margin-left: 10%;
    text-align: start;
    color: #121D85;
font-family: Metropolis;
font-style: normal;
}


.body h2{
    font-size: calc(0.9rem + 0.3vw);
    font-weight: 700;
    letter-spacing: 0.18px;
    line-height: 2rem; /* 21.42px */
    padding-top: 4vh;
}

.body p{
    font-size: calc(0.9rem + 0.2vw);
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 1.5rem; /* 21.42px */
}

@media screen and (max-width: 950px){
.image{
    height: fit-content;
}
.bubbel{
    margin-left: 30%;
    margin-top: 40%;
    width: 70%;
}
}