.devservices_con {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  overflow: hidden;
}

.dev_content {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: start;
  font-family: Metropolis;
  font-size: calc(0.5rem + 0.5vw);
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
}

.image_con {
  background-color: blueviolet;
  justify-content: center;
}

.description_con {
  width: fit-content;
  height: 30vh;
  margin-left: 10%;
  font-family: Metropolis;
  font-size: calc(0.5rem + 0.5vw);
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  justify-content: center;
  align-self: center;
}

.service {
  /* padding: 5vh 0 5vh 0; */
  width: fit-content;
  height: fit-content;
  text-align: start;
}


.panel {
    width: 100%;
    position: absolute;
    text-align: center;
    overflow: hidden;
}

.panelspan {
  position: relative;
  width: 70%;
  margin-left: 15%;
  display: block;
  top: 50%;
  padding-top: 20%;
  color: #121D85;

}
.imgCont {
  position: relative;
  display: block;
  top: 30%;
}

.title{
  font-family: Metropolis;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

}

.description {
  font-family: Metropolis;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

}


.pinContainerpanel {
  height: 30vh;
  width: 100%;
  position: absolute;
  text-align: center;
}

.testt {
  width: 100%;
  height: 100vh;
  background: linear-gradient(147.42deg, #121D85 3.51%, #000000 192.93%);

}

.mobile{
  width: 100%;
  display: none;
  flex-direction: column;
}

.mobiletext{
  width: 80%;
  margin-left: 10%;
  height: fit-content;
  justify-content: center;
  color: #121D85;
  text-align: start; 
  padding-bottom: 10%;
  padding-top: 1%;
  font-size: calc(0.9rem + 0.2vw);
}

@media screen and (max-width: 950px) {
  .devservices_con{
    display: none;
  }
  
.mobile{
  display: flex;
}
}