.blogintro_container{
    width: 100%;
    height: fit-content;
    justify-content: center;
    text-align: center;
    align-items: center;
    overflow: hidden;
    /* padding: 1vh 0 10vh 0; */
}

.sticky{
    width: 100vw;
    height: 90vh;
    background:transparent;
    padding-top: 10vh;
}
.contantContainer {
    position: absolute;
    height: 100%;
    width: 300vw;
    background: transparent;
    display: flex;
    flex-direction: row;
}

.slideContainer{
    position: relative;
}
.mobileView{
    display: none;
}


@media screen and (max-width: 950px){
    .slideContainer{
        display: none;
    }
    .mobileView{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}