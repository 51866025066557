/* Input container */
.input-container {
    position: relative;
    margin: 20px;
  }
  
  /* Input field */
  .input-field {
    font-family: Metropolis;
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-bottom: 2px solid #ccc;
    outline: none;
    background-color: transparent;
    color: #fff;
  }
  .input-field::placeholder {
    color: white;
    opacity: 0.6;
  }
  .textarea-field{
    font-family: Metropolis;
    /* display: block; */
    width: 100%;
    padding: 10px;
    font-size: 16px;
    outline: none;
    background-color: transparent;
    color: #fff;
    border: none;
    background-attachment: none;
    background-image:
        
        repeating-linear-gradient(transparent, transparent 35px, #ccc 35px, #ccc 36px, white 36px);
    line-height: 35px;
    padding: 8px 10px;
}
.textarea-field::placeholder {
  color: white;
  opacity: 0.6;
}
  /* Input label */
  .input-label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    color: rgba(204, 204, 204, 0);
    pointer-events: none;
    transition: all 0.3s ease;
  }
  
  /* Input highlight */
  .input-highlight {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width:0;
    background-color: #fff;
    transition: all 0.3s ease;
  }
  
  /* Input field:focus styles */
  .input-field:focus + .input-label {
    top: -20px;
    font-size: 12px;
    color: #fff;
  }
  
  .input-field:focus + .input-label + .input-highlight {
    width: 100%;
  }
  .textarea-field:focus + .input-label {
    top: -20px;
    font-size: 12px;
    color: #fff;
  }
  
 
  
  