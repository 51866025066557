.CreativeCommunityContainer{
    position: relative;
    height: 90vh;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
    width: fit-content;
    display: flex;
    flex-direction: row;
    font-size: calc(0.5rem + 1.5vw);
}

.imageContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 15vw;
    padding-left:10% ;
    /* padding: 0%  ; */
}

.contantContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 63vw;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center
}

.image1{
    position: relative;
    width: 90%;
    height: auto;
    object-fit: contain;
    margin-bottom: 100%;
    box-shadow: 2px 2px 20px white, 2px 2px 20px white;
    rotate: -10.11deg;
}

.image2{
    position: relative;
    width: 60%;
    height: auto;
    object-fit: contain;
    margin-left: 60%;
    margin-top: 20%;
    box-shadow: 2px 2px 20px white, 2px 2px 20px white;
    rotate: 6deg; 
}

.header{
    position: relative;
    width: 60%;
    font-family: Metropolis;
    font-size: calc(0.5rem + 1.5vw);
    font-weight: 700;
    text-align: center;
    color:#FFFFFF ;
    text-shadow: 1px 1px 8px rgba(255, 255, 255), 1px 1px 8px rgba(255, 255, 255);
}
.contant{
    position: relative;
    width: 80%;
    font-family: Metropolis;
    font-size: calc(0.9rem + 0.2vw);
    font-weight:lighter;
    line-height: 130%;
    text-align: center;
       
}

.imageMobile{
    display: none;
}
.imageMobile1{
    display: none;
}
.bubbleImg{
        position: absolute ;
        top: 200px ;
        left: -100px ;
}
@media screen and (max-width: 950px){
    .CreativeCommunityContainer{
        height: fit-content;
        width: 100%;
        padding-top: 30%;
        display: flex;
        flex-direction: column;
    }
    .contantContainer{
        
        width: 100%;
        height: fit-content;
        padding: 0%;
        margin: 0%;
    }
    .header{
        width: 80%;
        line-height: 200%;
    }
    .contant{
        width: 70%;
        line-height: 170%;
           
    }
    .imageContainer{
        display: none;
    }

    .imageMobile{
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .imageMobile1{
        display: flex;
        flex-direction: row;
        width: 50%;
        height: fit-content;
    }
    .image1{
        width: 40%;
        height: auto;
        margin-bottom: 0%;
        rotate: 15.74deg;
        margin-left: 70%;
        /* rotate: -10.11deg; */
    }
    .image2{
        width: 45%;
        margin-left: -8%;
        height: auto;
        margin-top: 10%;
        rotate: -13deg;
    }
    .bubbleImg{
        width: 30%;
        rotate: 90deg; 
}
}