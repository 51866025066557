
.card {
  position: relative;
  width: 50vw;
  border: none;
  height: 70vh;
  transition: width .5s;
  cursor: pointer;
  overflow: hidden;
  text-align: start;
  padding-right: 2%;
  font-size: calc(0.9rem + 0.2vw);
}

.card:hover{
  width: 100vw;
  height: 70vh;
  
  .card_content{
    width: 26.4vw;
  }
  .card_content p{
    display: flex;
    
  }
  .card_content h3 {
    bottom: 0;
    margin: 0px;
  }
  .descriptionContainer{
    left: 0;
    animation-delay: 2s;
  }
}

.card img {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}

.card_content {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  z-index: 10;
  width: 82%;
  height: fit-content;
  align-content: end;
  justify-content: end;
  padding: 5%;
  transition: transform 0.3s ease;
}

.card_content h3 {
  position: relative;
  height: fit-content;
  width: 100%;
  transition:bottom 0.5s ease;
  bottom: -50px;
  margin-top: -100px;
  padding-bottom: 0px;
}

.descriptionContainer{
  display: flex;
  height: fit-content;
  position: relative;
  width: 90%;
  left: 50%;
  transition: left 1s ease;
}
.card_content p {
  display: none;
  color: #fff;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 950px){
  .card {
    width: 100%;
    height: fit-content;
    padding-right: 0%;
  }
  .card_content {
    position: relative;
    width: 100%;
    align-content: end;
    justify-content: end;
    color: black;
    padding: 5% 0%;
  }
  .card img {
    width: 100%;
    height: fit-content;
    object-fit: cover;
  }
  .card_content h3 {
    bottom: auto;
    margin-top: 0px;
    padding-bottom: 0px;
    transition: none;
    color: #121d85;
  }
  .descriptionContainer{
    position: relative;
    width: 100%;
    left: 0%;
    margin-top: -5%;
    transition: none;
  }
  .card_content p {
    display: flex;
    color: #000;
    height: fit-content;
  }
  .card:hover{
    width: 100%;
    height: fit-content;
    padding-right: 0%;
    
    .card_content{
      width: 100%;
    }
  }
}