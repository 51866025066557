.OurMissionContainer{
    position: relative;
    height: 90vh;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
    width: fit-content;
    display: flex;
    flex-direction: row;
    padding-left: 1%;
}

.imageContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 20vw;
    /* padding: 0% 10% ; */
}

.contantContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50vw;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center
}

.image7{
    position: relative;
    width: 60%;
    margin-left: 20%;
    height: auto;
    object-fit: contain;
}

.header{
    position: relative;
    width: 60%;
    font-family: Metropolis;
    font-size: calc(0.5rem + 1.5vw);
    font-weight: 700;
    text-align: center;
    color:#FFFFFF ;
    text-shadow: 1px 1px 8px rgba(255, 255, 255), 1px 1px 8px rgba(255, 255, 255);
}
.contant{
    position: relative;
    width: 80%;
    font-family: Metropolis;
    font-size: calc(0.9rem + 0.2vw);
    font-weight:lighter;
    line-height: 130%;
    text-align: center;
       
}


.imageMobile{
    display: none;
}
.bubbleImg{
        position: absolute;
        bottom: -100px;
        right: 50px ;
        z-index: 10;
}

@media screen and (max-width: 950px){
    .OurMissionContainer{
        height: fit-content;
        width: 100%;
        padding-top: 0%;
        margin-top: -5%;
        display: flex;
        flex-direction: column;
    }
    .contantContainer{
        
        width: 100%;
        height: fit-content;
        padding: 0%;
        margin: 0%;
    }
    .header{
        width: 80%;
        line-height: 200%;
    }
    .contant{
        width: 70%;
        line-height: 170%;
           
    }
    .imageContainer{
        display: none;
    }
    .image6Container{
        display: none;
    }
    .imageMobile{
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .image8{
        width: 20%;
        height: auto;
        margin-bottom: 0%;
        rotate: 15deg;
        margin-left: -3%;
        box-shadow: 2px 2px 20px white, 2px 2px 20px white;
    }
    .bubbleImg{
        width: 30%;
        bottom: -10px;
        right: -50px ;
        rotate: 180deg;
        /* margin-left: 0px; */
    }
}