.contact_container{
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-image: url(../../assets/homebground.png);
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover; */
    background: linear-gradient(57.2deg, #121D85 10.21%, #000000 100%);
    padding: 8vh 0 20vh 0;
    font-family: Metropolis;
    overflow-x: hidden !important;
}

.contact_contant{
    width: 80%;
    margin-left: 10%;
    justify-content: center;
    align-content: center;
    text-align: center;
    margin-top: 10vh;
    color: #fff;
    font-size: calc(0.5rem + 1.5vw);
    font-family: Metropolis;


}

.header{
    text-shadow: 1px 1px 8px rgba(255, 255, 255), 1px 1px 8px rgba(255, 255, 255);
    /* padding-bottom: 1%; */
}

.contact_contant h4{
   font-weight: 400;
}
.form{
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 40% 60%;
    text-align: start;
    padding-top: 3%;
}

.info{
    border-right: 2px solid #fff;
    padding-top: 3vh;
    font-size: calc(0.9rem + 0.2vw);
    display: flex;
    flex-direction: column;
}

.info h2{
    padding-bottom: 10vh;
}
.socialMedia{
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 10% 90% ;
}
.info h4{
    text-align: start;
}
.icon{
    margin-top: 40%;
}
.socials{
    padding-top: 22vh;
    display: flex;
    flex-direction: row;
    /* column-gap: 2vw; */
}
.socialsIcon{
    width: 14%;
    z-index: 9;
    cursor: pointer;
}
.email{
    width: 97%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    color: #fff;
    /* background-color: blue */
}

.email_form{
    width: 100%;
    color: #fff;
    /* padding-left: 5vw; */
}

.email_form_row{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 48%);
    color: #fff;
    column-gap: 4%;
}

.color{
    background-color: transparent;
    color: #fff !important;
}

.select{
    margin: 5vh 0 5vh 0;
    padding-left: 4%;
}
.radioGroup{
    display:flex; 
    flex-direction: row ;
    column-gap: 1.5vw;
}
.button{
    width: 100%;
    text-align: end;
    margin: 10vh 0vw 0vh 0vw;
}

.button button{
    padding: 1vh 3vw 1vh 3vw;
    background-color: transparent;
    box-shadow: 0px 0px 5px 0px #FFF, 0px 0px 5px 0px #FFF;
    color: #FFF;
}

.button button:hover{
    box-shadow: 0px 0px 15px 0px #FFF, 0px 0px 15px 0px #FFF;
}
.bubbleImg{
    position: absolute;
    display: flex;
    overflow: hidden;
    width: 20%;
    right: 0% ;
}
.bubbleImg1{
    position: absolute;
    width: 20%;
    right: 85% ;
    rotate: 90deg;
     top: 130%;
   /* z-index: 100; */
}
.testt{
    position: relative;
    width: 100%; 
    left: 100px;
}
@media screen and (max-width: 950px){
    .contact_container{
        padding: 8vh 0 10svh 0;
    }
    .contact_contant{
        width: 95%;
        margin-left: 2.5%;
    }
    .form{
        grid-template-columns: 1fr;
        text-align: center;
    }
    .socialMedia{
        display: flex;
        flex-direction: row;
        width: fit-content;
        justify-content: start !important;
        align-items: start !important;
        margin-top: -2%;
        /* column-gap: 2% */
    }
    
    .socials{  
        margin:  10% 0;
    }
    .select h4{
        text-align: start;
        font-size: calc(1rem + 0.8vw);
        /* padding-left: 3%; */
    }
    .icon{
        position: relative;
        /* margin-top: 1%; */
    }
    .info{
        border-right: none;
        border-bottom: solid rgba(255, 255, 255, 0.3) 1px ;
        border-top: solid rgba(255, 255, 255, 0.3) 1px ;
        align-items: center;
    }
    .info h2{
        padding-bottom: 2vh;
    }
    .socials{
        justify-content: center;
        padding: 2%;
        height: fit-content;
    }
    .email{
        margin-left: 0;
        padding-top: 10%;
    }
    .radioGroup{
        flex-direction: column ;
        padding-left: 3%;
    }
    .button{
        text-align: start;
        margin: 5% 0vw 0vh 0vw;
        padding-left: 6%;
    }
    .button button{
        padding: 1.3vh 5vw 1.3vh 5vw;
    }
    .bubbleImg{
        position: absolute;
        display: flex;
        overflow: hidden;
        width: 40%;
        right: 0% ;
    }
    .bubbleImg1{
        width: 40%;
        right: 75% ;
        top:50%;
        rotate: 90deg;
        top: 20%;
    }
    .socialsIcon{
        width: 200%;
    }
    .testt{
        position: relative;
        width: 100%; 
        left: 50px;
        margin-top: 300%;
    }
}


@media screen and (max-width: 650px){
.email_form_row{
    grid-template-columns: 1fr;
}
.socialMedia{
    margin-top: -4%;
}
}