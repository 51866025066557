.OurVisionContainer{
    position: relative;
    height: 90vh;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
    width: fit-content;
    display: flex;
    flex-direction: row;
}

.imageContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 20vw;
    justify-content: flex-start;
    /* padding: 0% 10% ; */
}

.contantContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50vw;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center
}


.image2{
    position: relative;
    width: 40%;
    height: auto;
    object-fit: contain;
    margin-left: 60%;
    margin-top: 80%;
    box-shadow: 2px 2px 20px white, 2px 2px 20px white;
    rotate: 6deg; 
}

.image8{
    position: relative;
    width: 50%;
    height: auto;
    object-fit: contain;
    margin-top: 20%;
    margin-left: 20%;
    box-shadow: 2px 2px 20px white, 2px 2px 20px white;
    rotate: -4.7deg;
}

.header{
    position: relative;
    width: 60%;
    font-family: Metropolis;
    font-size: calc(0.5rem + 1.5vw);
    font-weight: 700;
    text-align: center;
    color:#FFFFFF ;
    text-shadow: 1px 1px 8px rgba(255, 255, 255), 1px 1px 8px rgba(255, 255, 255);
}
.contant{
    position: relative;
    width: 80%;
    font-family: Metropolis;
    font-size: calc(0.9rem + 0.2vw);
    font-weight:lighter;
    line-height: 130%;
    text-align: center;
       
}

.image9Container{
    position: relative;
    height: 100%;
    justify-content:end;
    width: 20vw;
    /* text-align: end; */
}

.image9{
    position: relative;
    width: 90%;
    height: auto;
    object-fit: contain;
    margin-top: 120%;
    margin-left: 30%;
    box-shadow: 2px 2px 20px white, 2px 2px 20px white;
    rotate: 7.14deg; 
}


.imageMobile{
    display: none;
}

.bubbleImg{
    position: absolute;
    top: 20px;
    right: -120px;
    rotate: 180deg;
  }

@media screen and (max-width: 950px){
    .OurVisionContainer{
        height: fit-content;
        width: 100%;
        padding-top: 0%;
        margin-top: -5%;
        display: flex;
        flex-direction: column;
    }
    .contantContainer{
        
        width: 100%;
        height: fit-content;
        padding: 0%;
        margin: 0%;
    }
    .header{
        width: 80%;
        line-height: 200%;
    }
    .contant{
        width: 70%;
        line-height: 170%;
           
    }
    .imageContainer{
        display: none;
    }
    .image9Container{
        display: none;
    }
    .imageMobile{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 5%;
        padding-bottom: 5%;
    }
    .image7{
        width: 30%;
        height: auto;
        margin-bottom: 0%;
        rotate: 13deg;
        margin-left: 80%;
        box-shadow: 2px 2px 20px white, 2px 2px 20px white;
    }
    .bubbleImg{
        display: none;
      }
}