.navbar_container {
  width: 100%;
  height: 10vh;
  /* background-image: url("../../../assets/homebground.png");
  background-size: cover;
  background-repeat: no-repeat; */
  background: linear-gradient(57.2deg, #121D85 10.21%, #000000 100%);
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 99;
}

.navbar_container_before {
  width: 100%;
  height: 10vh;
  background-image: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 99;
}

.logo_conainer {
  display: flex;
  margin-right: 32%;
  align-items: center;
}

.routing_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #fff;
  align-items: center;
  margin-right: 9.5%;
  font-family: Metropolis;
  font-size: calc(0.7rem + 0.4vw);
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-transform:uppercase;
}

.routing_container p{
    margin-left: 2vw;
}

.routing_container button{
    width: 185px;
    height: 34px;
    background-color: transparent;
    border: 1px solid white;
    color: #fff;
    text-transform:uppercase;
    margin-left: 2vw;
    font-family: Metropolis;
    cursor: pointer;
    box-shadow: 0px 0px 5px 0px #FFF, 0px 0px 5px 0px #FFF;
}


.routing_container button:hover{
  box-shadow: 0px 0px 15px 0px #FFF, 0px 0px 15px 0px #FFF;
}

.mobileview {
  width: 100%;
  height: 10vh;
  /* background-image: url("../../../assets/homebground.png");
  background-size: cover;
  background-repeat: no-repeat; */
  background: linear-gradient(57.2deg, #121D85 10.21%, #000000 100%);
  display: none;
  grid-template-columns: 30% 40% 30%;
  position: fixed;
  z-index: 99;
}

.mobileview_before {
  width: 100%;
  height: 10vh;
  background-image: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  display: none;
  grid-template-columns: 30% 40% 30%;
  position: fixed;
  z-index: 99;
}

.mobilemenu{
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 950px){
  .navbar_container{
    display: none;
  }

  .navbar_container_before{
    display: none;
  }

  .mobileview{
    display: grid;
  }
  
  .mobileview_before{
    display: grid;
  }
}