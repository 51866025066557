.team_container {
  position: relative;
    width: 100%;
    height: fit-content;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 5vh 0 0vh 0;
  }
  
  .team_content {
    position: relative;
    width: 82%;
    margin-left: 9.6%;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #fff;
    font-family: Metropolis;
    font-style: normal;
  }


  .title_container{
    position: relative;
    display: flex;
    flex-direction: column;
      text-align: center;
    font-size: calc(0.5rem + 1.5vw);
  }
  
  .title_container h1{
    position: relative;
    font-weight: 800;
    letter-spacing: 0.18px;
    /* line-height: 2.5rem; 21.42px */
    text-shadow: 2px 2px 20px rgba(255, 255, 255), 2px 2px 20px rgba(255, 255, 255);
  }
  
  .title_container p{
    position: relative;
    font-size: calc(0.9rem + 0.2vw);
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 1.5rem; /* 21.42px */
  }
  
  .cardsContainer{
      position: relative;
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      margin-top: 5vh;
      justify-content: center;
      text-align: center;
      align-items: center;
      padding-bottom: 5%;
  }

  .arrowDesktop{
    display: flex ;
  }
.swiper{
  display: none;
}
.bubbleImg{
  position: absolute;
  right: -20px;
  /* rotate: -90deg; */
  z-index: 90;
  overflow: hidden;
  
}
.card {
  height: fit-content;
  margin-bottom: -1px;
  position: relative;
  display: inline-block;
}
.textContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(18, 29, 133, 0.82);
  color: white;
  padding: 1%;
  text-align: center;
  font-family: Metropolis;
  font-size: calc(0.7rem + 0.4vw);
  letter-spacing: 0em;
  opacity: 0;
  transition: opacity 1s, transform 1s;
  transform: translateY(100%); 
}

.card:hover .textContainer {
  opacity: 1;
  transform: translateY(0); 
}
.textContainerMob{
  display: none;
}

  @media screen and (max-width: 950px){
    .cardsContainer{
      position: relative;
      display: none;
    }
    .swiper{
      position: relative;
      display: flex;
    }
    .textContainer {
     display: none;
    }
    .textContainerMob {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 100%;
      bottom:  0;
      left: 0;
      margin: 0; 
      background: rgba(18, 29, 133, 0.82);
      color: white;
      /* padding: 1%; */
      text-align: center;
      font-family: Metropolis;
      padding: 5% 0 3% 0;
      font-size: calc(0.6rem + 0.3vw);
      /* letter-spacing: 0em; */
    }
    .textContainerMob h4, p{
      position: relative;
      margin: 0;
      padding-bottom: 2%;
    }
    .card:hover .textContainerMob {
      opacity: 1;
      transform: none; 
    }
  }
 