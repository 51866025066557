.card_container{
    width: 100%;
    height: fit-content;
    background: transparent;
    font-family: Metropolis;
    font-size: calc(0.9rem + 0.2vw);
    letter-spacing: 0.01rem;
    -webkit-transition: all 0.8s ease;
   -moz-transition: all 0.8s ease;
   transition: all 0.8s ease;
}

.card_img{
    position: relative;
    width: 100%;
    height: 30vh;
    object-fit: contain;
    -webkit-transition: all 0.8s ease;
   -moz-transition: all 0.8s ease;
   transition: all 0.8s ease;
}

.card_container:hover{
  .card_img{
  height: 35vh;
  }
}

.card_content{
    width: 90%;
    left: 0;
    text-align: start;
    row-gap: 1vh;
    font-size: calc(0.9rem + 0.2vw);
}


.card_container::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0.15);
    height: 2px;
    bottom: 0;
    left: 0%;
    color: #036ADB;
    background-color: #036ADB;
    transform-origin: bottom left;
    transition: transform 0.5s ease-out;
  }
  
  .card_container:hover::after {
    transform: scaleX(0.9);
    transform-origin: bottom left;
    color: #036ADB;
  }
  