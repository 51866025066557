.footer {
  position: relative;
  width: 100%;
  height: fit-content;
  justify-content: center;
  text-align: center;
  align-items: center;
  /* background-image: url("../../../assets/footerbg.png");
  background-size: cover;
  background-repeat: no-repeat; */
  background: linear-gradient(57.2deg, #121D85 10.21%, #000000 100%);
  padding: 4vh 0 4vh 0;
  row-gap: 3vh;
  color: #fff;
  z-index: 99;
}

.socials{
    display: flex;
    flex-direction: row;
    column-gap: 1.8vw;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0vh 0 1vh 0;
}

.logoo{
  width: 10%;
  height: 10%;
}

@media screen and (max-width: 750px){
  .logoo{
    width: 30%;
    height: 30%;
  }
  }