.DesignServicesContainer{
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 13vh 0 0vh 0;
    font-family: Metropolis;
    font-weight: 400;
    letter-spacing: 0.1em;
    font-size: calc(0.5rem + 1.5vw);
}

.title{
    width: 100%;
    /* line-height: 0%; */
}

.bigContainer{
    width: 92%;
    margin-left: 4%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.contentContainer{
    width: 82%;
    margin-left: 9%;
    display: flex;
    flex-direction: column;
    text-align: start;
    font-family: Metropolis;
    font-size: calc(0.5rem + 0.5vw);
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    padding-top: 5%;
    overflow: hidden;
}

.description{
    width: 100%;
    padding-top: 2%;
    font-size: calc(0.9rem + 0.2vw);
}


.characterDesignVideoCont{
    width: 130%; 
    margin-left: -15%;
}
.webDesignVideoCont{
    width: 150%;
    margin-left: -25%;
}
.nftVideoCont{
    width: 100%;
    height: 70vh;
    overflow: hidden;
}
.nftDescription{
    width: 100%;
    padding-bottom: 5%;
    padding-top: 2%;
    font-size: calc(0.9rem + 0.2vw);
}
.testt{
    display: none;
}

@media screen and (max-width: 950px){
.bigContainer{
    grid-template-columns: 100%;
}
.characterDesignVideoCont{
    width: 100%; 
    margin-left: 0%;
}
.webDesignVideoCont{
    width: 100%;
    margin-left: 0%;
}
.description{
    font-weight: 500;
}
.nftVideoCont{
    display: none;
}
.testt{
    display: flex;
}
.nftDescription{
    width: 100%;
    padding-bottom: 5%;
    font-weight: 500;
    padding-bottom: 15%;
}
.nftContainer{
    width: 92%;
    margin-left: 4%;
}
.DesignServicesContainer{
    padding: 5vh 0 0vh 0;
}
}